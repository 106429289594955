import { useContext, createContext, useState, useRef, useEffect } from "react";
import { Image } from "../image";

import {
  DiamondIcon,
  MessagesSquareIcon,
  LinkIcon,
  MenuIcon,
  Clock,
  Coins,
} from "lucide-react";

import {
  RarityTypeType,
  GameModeType,
  ClassTypeType,
  Listing,
  ListingModeType,
  PriceTypeType,
} from "@repo/backend/schemas";
import { Textarea } from "@repo/ui/textarea";
import { ParallelogramIcon, StarIcon } from "../../icons";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../dialog";

import { cn } from "@repo/ui/utils";

import { replaceBracedContent } from "../../utils/string";
import { GameMode } from "../game-mode";
import { Button } from "../button";

type Affix = {
  displayText: string;
  isGreater: boolean;
};

type User = {
  id: string;
  name: string;
  online: boolean;
  isCurrentUser: boolean;
};

type ContextType = {
  title: string;
  listingMode: ListingModeType;
  rarity: RarityTypeType;
  implicits: string[];
  affixes: Array<Affix>;
  greaterAffixes: number;
  effect: string;
  image: string;
  fallbackImage?: string;
  gameMode: GameModeType;
  power: number;
  levelRequirement: Listing["itemLevelRequired"];
  classType: ClassTypeType;
  typeName: string;
  price: string;
  user: User;
  priceType: PriceTypeType;
};

const Context = createContext({} as ContextType);

type ItemCardProps = ContextType;

export function ItemCard(props: ItemCardProps) {
  return (
    <Context.Provider value={props}>
      <div className="relative h-full">
        <div className="flex h-full flex-col overflow-hidden rounded-md border-1 border-accent-700 bg-accent-1000 bg-[image:var(--item-card-bg-image)] bg-fixed bg-top bg-blend-overlay">
          <ItemCardHeader />
          <ItemCardBody />
          <ItemCardFooter />
        </div>
      </div>
    </Context.Provider>
  );
}

function ItemCardHeader() {
  const { user, listingMode } = useContext(Context);
  return (
    <div className="px-3 pt-3 py-1 bg-accent-1000 border-b border-accent-700">
      <div className="flex items-start justify-between">
        <div>
          <div className="flex flex-col ">
            <div className="mb-1 flex items-center gap-2">
              <DiamondIcon
                className={cn("h-5 w-5 text-green-100", {
                  "text-online": user.online,
                  "text-offline": !user.online,
                })}
              />
              <div className="text-sm flex flex-col">
                <span>{user.name}</span>
                <span
                  className={cn("text-xs text-accent-200", {
                    "text-online": user.online,
                    "text-offline": !user.online,
                  })}
                >
                  {user.online ? "Online" : "Offline"}
                </span>
              </div>
              <p
                className={cn(
                  "rounded capitalize bg-primary-400/50 p-0.5 w-fit px-1.5 font-accent text-white text-xs ml-auto self-start",
                  {
                    "bg-primary-400/50": listingMode === "SELLING",
                    "bg-blue-400/50": listingMode === "BUYING",
                  }
                )}
              >
                {listingMode}
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="flex items-center gap-2">
            <button>
              <MessagesSquareIcon className="h-5 w-5 fill-yellow-200" />
            </button>
            <button>
              <LinkIcon className="h-5 w-5 stroke-yellow-50" />
            </button>
            <button>
              <MenuIcon className="h-5 w-5 stroke-yellow-50" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function ItemCardBody() {
  const { title, typeName, power, image, fallbackImage } = useContext(Context);
  const [imgSrc, setImgSrc] = useState(image);
  const [isImageLoading, setIsImageLoading] = useState(true);

  return (
    <div className="flex-1 px-2.5 sm:px-5 py-3">
      <div className="mb-1 flex items-start">
        <div className="w-full flex gap-1 flex-col">
          <h3 className="font-bold capitalize text-unique flex flex-row items-start justify-start gap-1.5">
            <span className="basis-auto tracking-wide">{title}</span>
            <GreaterAffixList />
          </h3>
          <div className="mb-2">
            <h3 className="text-sm font-bold capitalize text-unique">
              {typeName}
            </h3>
            <p className="text-sm">
              <span className="font-number">{power}</span> Item Power
            </p>
          </div>
        </div>

        <div className="relative h-24 w-24">
          <div
            className={cn(
              "absolute right-0 top-1 h-24 w-24 transition-opacity duration-300",
              {
                "opacity-0": isImageLoading,
              }
            )}
          >
            <Image
              className="object-contain fill"
              alt={title}
              fill
              sizes="60px"
              src={imgSrc}
              onLoad={() => {
                setIsImageLoading(false);
              }}
              onError={() => {
                if (imgSrc !== fallbackImage) {
                  setImgSrc(fallbackImage as string);
                }
              }}
            />
          </div>
        </div>
      </div>
      <ItemCardImplicitsList />
      <ItemCardDivider />
      <ItemCardAffixesList />
      <Effect />
      <ItemCardDivider />
    </div>
  );
}

function ItemCardFooter() {
  const { price, levelRequirement, gameMode, priceType } = useContext(Context);
  return (
    <div className="flex flex-col justify-center">
      <div className="m-none my-4 flex items-center justify-between px-3 font-bold">
        <GameMode mode={gameMode} className="max-w-[20px]" />
        <div className="gap-0.2 flex flex-col items-end justify-center text-xs">
          <p>
            Requires Level
            <span className="text-accent font-number pl-1">
              {levelRequirement}
            </span>
          </p>
          <ClassType />
        </div>
      </div>
      <ListedAt />
      <div className="flex h-14 items-center justify-between border-t border-accent-700 bg-accent-1000 bg-[image:url('/ui/background/texture-card.png')] px-3">
        <div className="flex flex-col justify-center gap-1">
          <span className="text-accent">
            {priceType === "FIXED" ? "Exact Price" : "Negotiable"}
          </span>
          <div className="flex items-center gap-2 text-yellow-200">
            <CoinSVG />
            <span className="text-yellow-200 font-number text-shadow-accent">
              {price === "0" ? "-" : price}
            </span>
          </div>
        </div>
        <ActionButton />
      </div>
    </div>
  );
}

function Effect() {
  const [open, setOpen] = useState<boolean>(false);
  const { effect } = useContext(Context);

  const truncateText = (text: string, limit: number) => {
    if (text.length <= limit) return text;
    return text.slice(0, limit).trim();
  };

  return (
    <div className="flex gap-1 items-start justify-start text-sm text-unique mt-0.5">
      <div className="min-h-[16px] min-w-[16px] flex items-end justify-center">
        <StarIcon className="h-3 w-3" />
      </div>
      <div className="self-start">
        <p>
          <span
            dangerouslySetInnerHTML={{
              __html: open
                ? replaceBracedContent(
                    "<span class='text-[#AAAAFF] font-accent'>$0</span>",
                    effect
                  )
                : replaceBracedContent(
                    "<span class='text-[#AAAAFF] font-accent'>$0</span>",
                    effect
                  ),
            }}
          />
          {/* <button
            className="inline-block text-nowrap text-white"
            onClick={() => setOpen((prev) => !prev)}
          >
            ...{(open && "read less") || "read more"}
          </button> */}
        </p>
      </div>
    </div>
  );
}

function GreaterAffixList() {
  const { greaterAffixes } = useContext(Context);
  return (
    <div className="flex items-start gap-0.5">
      {Array.from({ length: greaterAffixes }).map((_, index) => {
        return (
          <div className="relative min-h-[16px] min-w-[16px]" key={index}>
            <Image
              alt="Greater Affix"
              fill
              sizes="auto"
              src="/assets/card/decorations/greater-affix.webp"
            />
          </div>
        );
      })}
    </div>
  );
}

function ItemCardImplicitsList() {
  const { implicits } = useContext(Context);
  return (
    <div>
      {/* <p className="font-diablo">596 damage per second(MOCKED)</p> */}
      <ul>
        {implicits.map((implicit, index) => {
          return (
            <li
              className="flex items-center text-sm text-accent-100 gap-2 items-start"
              key={index}
            >
              <div className="min-h-[16px] min-w-[16px] flex items-center justify-center mt-0.5">
                <ParallelogramIcon className="mr-0.5 h-3 w-3" />
              </div>
              <span
                dangerouslySetInnerHTML={{
                  __html: replaceBracedContent(
                    "<span class='font-accent text-accent'>$0</span>",
                    implicit
                  ),
                }}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function ItemCardAffixesList() {
  const { affixes } = useContext(Context);

  return (
    <ul>
      {affixes.map((affix, index) => {
        return (
          <li
            key={index}
            className="flex items-start justify-start gap-x-1.5 text-sm text-accent-100"
          >
            <div className="relative flex min-h-[16px] min-w-[16px] items-center justify-center mt-0.5">
              {affix.isGreater ? (
                <Image
                  alt="Greater Affix"
                  fill
                  sizes="auto"
                  src="/assets/card/decorations/greater-affix.webp"
                />
              ) : (
                <ParallelogramIcon className="h-3 w-3" />
              )}
            </div>
            <span
              dangerouslySetInnerHTML={{
                __html: replaceBracedContent(
                  "<span class='font-accent text-accent'>$0</span>",
                  affix.displayText
                ),
              }}
            />
          </li>
        );
      })}
    </ul>
  );
}

function ItemCardDivider() {
  return (
    <div className="relative my-3 flex h-2 justify-center">
      <div className="absolute -top-0.5 h-4 w-full">
        <Image
          alt="card separator line"
          fill
          sizes="auto"
          src={`/assets/card/card-separator-line.png`}
          className="absolute bg-no-repeat object-contain"
        />
      </div>
      <div className="relative h-3 w-4">
        <Image
          alt=""
          fill
          sizes="auto"
          src={`/assets/card/card-separator-details.png`}
          className=""
        />
      </div>
    </div>
  );
}

function ClassType() {
  const { classType } = useContext(Context);
  const capitalized = classType
    .toLowerCase()
    .replace(/\b\w/g, (letter) => letter.toUpperCase());
  return <p>{capitalized}</p>;
}

function ListedAt() {
  return (
    <div className="flex items-center justify-between border-t border-accent-700 px-3 py-3 bg-accent-1000">
      <p className="flex items-center text-[12px] font-bold text-accent-200 font-number">
        <Clock className="mr-1 h-[10px] w-[10px]" />
        Listed about 5 hours ago
      </p>

      <p className="flex items-center text-[12px] font-bold text-accent-200 font-number">
        <Clock className="mr-1 h-[10px] w-[10px]" />
        Expires in 2 hours
      </p>
    </div>
  );
}

function ActionButton() {
  const { listingMode, priceType } = useContext(Context);
  const buttonText = listingMode === "SELLING" ? getBuyText() : "Sell";

  function getBuyButtonBackground() {
    return priceType === "FIXED" ? "sky" : "mystic";
  }

  function getBuyText() {
    return priceType === "FIXED" ? "Buy" : "Offer";
  }
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          variant="effect"
          background={
            listingMode === "SELLING" ? getBuyButtonBackground() : "primary"
          }
          size="md"
        >
          <p className="text-white uppercase px-2">{buttonText}</p>
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="uppercase">Contact Panel</DialogTitle>
          <DialogDescription>Description here</DialogDescription>
        </DialogHeader>
        <div className="">
          <Textarea className="!min-h-96 !bg-accent-700" />
        </div>
        <DialogFooter></DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function CoinSVG() {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 512 512"
      className="inline-flex text-yellow-200"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2l0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5V176c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336V300.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4V304v5.7V336zm32 0V304 278.1c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5V272c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5V432c0 44.2-86 80-192 80S0 476.2 0 432V396.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z"></path>
    </svg>
  );
}
