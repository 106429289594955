import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "../../utils/tailwind";
import { LoadingSpinner } from "../loading";

const buttonContainer = cva(
  "flex items-center px-3 w-auto rounded-md button-container font-accent box-border relative cursor-pointer select-none justify-center gap-2 whitespace-nowrap text-md font-medium ring-offset-background focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "text-white font-bold hover:brightness-125 active:brightness-150",
        destructive:
          "bg-primary-700 text-white [&:not(:disabled)]:hover:bg-primary-600",
        ghost:
          "[&:not(:disabled)]:hover:bg-accent-800 transition-all duration-200",
        outline: "border border-accent-600 bg-accent-1000",
        link: "text-white underline-offset-4 [&:not(:disabled)]:hover:underline",
        effect: "text-white whitespace-nowrap rounded-none",
      },
      background: {
        accent: "bg-accent-800 ",
        blue: "bg-blue-500/70",
        orange: "bg-orange-500/70",
        primary: "bg-primary-500/70",
        sky: "bg-sky-500/70",
        green: "bg-green-500/70",
        teal: "bg-teal-500/70",
        mystic: "bg-mystic-500/70",
      },
      size: {
        xs: "h-6 text-xs",
        sm: "h-7 text-xs",
        md: "h-8 text-sm",
        lg: "h-10 text-md",
        icon: "h-10 w-10",
      },
      disabled: {
        true: "text-accent-100 cursor-default opacity-50 pointer-events-none",
      },
    },
    defaultVariants: {
      size: "md",
      background: "accent",
    },
    compoundVariants: [
      {
        variant: "ghost",
        background: "accent",
        className: "bg-transparent",
      },
      {
        variant: "effect",
        background: "teal",
        className: "bg-teal-900/90 contrast-110 brightness-80",
      },
      {
        variant: "effect",
        background: "primary",
        className: "bg-primary-800/90 contrast-140 brightness-80",
      },
      {
        variant: "effect",
        background: "green",
        className: "bg-green-300 contrast-120 brightness-70",
      },
      {
        variant: "effect",
        background: "blue",
        className: "bg-blue-100 contrast-110",
      },
      {
        variant: "effect",
        background: "orange",
        className: "bg-orange-300",
      },
      {
        variant: "effect",
        background: "accent",
        className: "bg-accent-100",
      },
      {
        variant: "effect",
        background: "accent",
        className: "bg-accent-100",
      },
      {
        variant: "effect",
        background: "sky",
        className: "bg-sky-500/40",
      },
      {
        variant: "effect",
        background: "mystic",
        className: "bg-mystic/50",
      },
    ],
  }
);

const buttonInner = cva(
  "inline-flex w-full h-full items-center absolute inset-0",
  {
    variants: {
      variant: {
        default: "pointer-events-none",
        destructive: "",
        outline: "",
        ghost: "",
        link: "",
        effect:
          "effect-button brightness-100 hover:brightness-120 transition-all duration-200",
      },
      size: {
        xs: "",
        sm: "",
        md: "",
        lg: "",
        icon: "",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "md",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    Omit<VariantProps<typeof buttonInner>, "disabled">,
    Omit<VariantProps<typeof buttonContainer>, "disabled"> {
  asChild?: boolean;
  loading?: boolean;
  loadingLabel?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      size,
      asChild = false,
      loading,
      loadingLabel,
      children,
      disabled,
      variant = "default",
      background,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        ref={ref}
        disabled={disabled}
        className={cn(
          buttonContainer({ variant, size, background, disabled }),
          className
        )}
        {...props}
      >
        <div className={cn(buttonInner({ variant, size }))} />
        <div className="flex items-center justify-center pointer-events-none z-50 gap-2">
          {loading ? (
            <>
              <LoadingSpinner />
              <span className="text-sm animate-pulse capitalize">
                {loadingLabel || "Loading..."}
              </span>
            </>
          ) : (
            <>
              <span
                className={cn({
                  "text-shadow-accent": variant === "effect",
                })}
              >
                {children}
              </span>
            </>
          )}
        </div>
      </Comp>
    );
  }
);

Button.displayName = "Button";

export { Button, buttonInner as buttonVariants };
